import { useMutation, useQuery } from "@apollo/client";
import { Celebration, Chat, Favorite, Settings } from "@mui/icons-material";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ACCEPT_MATCH, REJECT_MATCH } from "src/components/auth/data/mutation";
import { USER_PENDING_REQUESTS } from "src/components/auth/data/queries";
import { useUser } from "src/components/auth/hooks/UserProvider";
import Card from "src/components/Card";
import Loading from "src/components/Loading";
import Notifications from "src/components/Notification/notifications";
import { SideBarContext } from "src/context/SidebarProvider";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import Noaccess from "./NoAccessPage";
import SubscribePopUp from "src/components/SubscribePopUp";

interface MUSER {
  __typename: String;
  id: String;
  occupation: String;
  last_name: String;
  profile_image_url: String;
  first_name: String;
  last_online: String;
}
const Happy =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/happy.jpg";
const NoUser =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/no_user.png";
const ProfileSuggestion = () => {
  const { user: us } = useUser();
  const { singleUser } = useContext(SideBarContext);
  const {
    data,
    loading: loading_user,
    refetch,
  } = useQuery(USER_PENDING_REQUESTS, {
    variables: {
      userPendingRequestsId: Number(us?.id),
      suggestedBy: "HANDLER",
    },
    fetchPolicy: "network-only",
  });
  const [acceptMatchHandler] = useMutation(ACCEPT_MATCH);
  const [rejectMatchHandler] = useMutation(REJECT_MATCH);
  const [search, setSearch] = useState("");
  const [active, setActive] = useState(true);
  const [isMatched, setIsMatched] = useState(false);
  const [userMatched, setUserMatched] = useState<MUSER | null>(null);
  const [cycle, setCycle] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [displayData, setDisplayData] = useState<any>([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const userRef = useRef<HTMLInputElement | null>(null);
const [access, setAccess] = useState(true);
  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let text = e.target.name;
  //   text = e.target.value;
  //   setSearch(text);
  //   // filter logic here....
  // };

  const navigate = useNavigate();

  const user = singleUser?.name;

  const handleMatchProfileNavigation = (
    match: any,
    status: any,
    matchId: any
  ) => {
    navigate(
      `/dashboard/request-profile/${match}?mstatus=${status}&mid=${matchId}`
    );
  };

  const mainDisplayedUsers = data?.userPendingRequests
    ?.map?.((i) =>
      i?.user_matched?.first_name === us?.first_name &&
      i?.user_matched?.last_name === us?.last_name
        ? {
            ...i,
            user_matched: i?.user_match,
            user_match: i?.user_matched,
            user_matched_status: i?.user_match_status,
            user_match_status: i?.user_matched_status,
          }
        : i
    )
    ?.filter?.(
      (i) =>
        (i?.user_match_status === null && i?.user_matched_status === null) ||
        (i?.user_match_status === null && i?.user_matched_status === true)
    );

  function handleClickOutside(event) {
    if (!userRef?.current?.contains(event.target)) setActive(true);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [userRef]);

  useEffect(() => {
    if (search?.trim?.()) {
      const filteredData = mainDisplayedUsers?.filter?.((i: any) =>
        i?.user_matched?.first_name
          ?.toLowerCase?.()
          ?.includes?.(search?.toLowerCase?.())
      );
      setDisplayData(filteredData);
    } else {
      setDisplayData(mainDisplayedUsers);
    }
  }, [search, data]);

  const handleAcceptMatch = (prop) => {
    if (us.subscription.name !== "basic") {
      setActionLoading(true);
      acceptMatchHandler({
        variables: {
          payload: {
            match_id: Number(prop.id),
            user_id: Number(us.id),
          },
        },
        onCompleted: (data) => {
          refetch();
          setActionLoading(false);
          toast.success("Like successful");
          if (prop?.user_matched_status === true) {
            setCycle("complete");
          } else {
            setCycle("partial");
          }
          setUserMatched(prop?.user_matched);
          setIsMatched(true);
        },
        onError: (error) => {
          console.log(error);
          toast.error("An error occured");
          setActionLoading(false);
        },
      });
    }
  };

  const handleRejectMatch = (prop) => {
    if (us.subscription.name !== "basic") {
      setActionLoading(true);
      rejectMatchHandler({
        variables: {
          payload: {
            match_id: Number(prop?.id),
            user_id: Number(us?.id),
          },
        },
        onCompleted: (data) => {
          refetch();
          setActionLoading(false);
        },
        onError: (error) => {
          console.log(error);
          setActionLoading(false);
          toast.error("An error occured");
        },
      });
    }
  };

//   if(access){
//     return (
//       <>
//       <div style={{zIndex:70}}  className="fixed top-0 bottom-0 left-0 right-0 bg-black bg-opacity-70 z-50  h-full overflow-y-auto ">
//         <div className="py-10 flex justify-center items-center px-6  mx-auto min-h-screen ">
//  <SubscribePopUp/> 
//         </div>

//       </div>
    
//       </>
//     )
//   }
  return (
    // <>
    //   {us.subscription.name === "basic" ? (
    //     <Noaccess
    //       header="No Access"
    //       message=" You have no access to your Handler Suggestions. Beef up your dating game today, by becoming a premium subscriber"
    //     />
    //   ) : (
    <div className=" md:bg-white">
      <ResponsiveOverlay
        title={
          cycle === "partial"
            ? "Request sent to " + userMatched?.first_name
            : "Matched with " + userMatched?.first_name
        }
        size="small"
        isOpen={isMatched}
        setIsOpen={() => {
          setUserMatched(null);
          setIsMatched(false);
          setCycle("");
        }}
      >
        {cycle === "complete" && (
          <div className="p-5 space-y-5 pb-10">
            <div className="flex justify-center items-center">
              <div className="w-20 h-20 lg:w-32 lg:h-32 rounded-full bg-orange-100 text-blue-600 flex justify-center items-center text-xl md:text-4xl">
                <Celebration fontSize="inherit" />
              </div>
            </div>
            <div className="">
              <h2 className="text-sm text-center font-bold ">
                Congratulations!!! It's a match
              </h2>
              <p className="text-xs py-2 font-medium text-center">
                You have successfully matched with {userMatched?.first_name}{" "}
                {userMatched?.last_name}
              </p>
              <p className="text-xs text-center">
                You can take this further by dropping a direct message. We are rooting for you
              </p>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <button
                onClick={() => {
                  navigate("/dashboard/messages");
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-[#081A51] text-white border border-[#081A51] text-xs font-normal "
              >
                Message {userMatched?.first_name}
              </button>
              <button
                onClick={() => {
                  setUserMatched(null);
                  setIsMatched(false);
                  setCycle("");
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-white text-[#081A51] border border-[#081A51] text-xs font-normal "
              >
                Cancel
              </button>
            </div>
          </div>
        )}
        {cycle === "partial" && (
          <div className="p-5 space-y-5 pb-10">
            <div className="flex justify-center items-center">
              <div className="w-20 h-20 lg:w-32 lg:h-32 rounded-full bg-orange-100 flex justify-center items-center text-pink-500 text-xl md:text-4xl">
                <Favorite fontSize="inherit" />
              </div>
            </div>
            <div className="">
              <h2 className="text-sm text-center font-bold ">
                Match request sent!!!
              </h2>
              <p className="text-xs py-2 font-medium text-center">
                You have sent a match request to {userMatched?.first_name}{" "}
                {userMatched?.last_name}
              </p>
              <p className="text-xs text-center"></p>
            </div>
            <div className="flex flex-col justify-center items-center gap-5">
              <button
                onClick={() => {
                  setUserMatched(null);
                  setIsMatched(false);
                  setCycle("");
                }}
                className="px-4 py-2 rounded-lg uppercase block w-full sm:w-1/2 bg-[#081A51] text-white border border-[#081A51] text-xs font-normal "
              >
                Continue
              </button>
            </div>
          </div>
        )}
      </ResponsiveOverlay>
      {actionLoading && <Loading />}
      {loading_user ? (
        <div className="gap-3 md:p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
            <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
            <div className="p-6">
              <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
            </div>
          </div>
          <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
            <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
            <div className="p-6">
              <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
            </div>
          </div>
          <div className="max-w-sm w-full mx-auto bg-white shadow-md rounded-lg overflow-hidden">
            <div className="animate-pulse bg-gray-200 h-24 w-full"></div>
            <div className="p-6">
              <div className="animate-pulse bg-gray-200 w-1/4 h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-full h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-3/4 h-4 mb-2"></div>
              <div className="animate-pulse bg-gray-200 w-1/2 h-4 mb-2"></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="md:p-3 md:border-b border-gray-300">
            <h2 className="text-sm sm:text-xl font-semibold">
              Suggestions from handler
            </h2>
            <p className="text-[8px] sm:text-sm">
              Explore and match with a partner of your dreams
            </p>
          </div>
          {displayData?.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-5 md:px-3 py-7 ">
              {displayData?.map((userMatch: any, i: number) => {
                return (
                  <div key={i} className="">
                    <Card
                      img={userMatch?.user_matched?.profile_image_url}
                      name={userMatch?.user_matched?.first_name}
                      occupation={userMatch?.user_matched?.occupation}
                      userMatch={userMatch}
                      request
                      btnClickDecline={handleRejectMatch}
                      btnClickAccept={handleAcceptMatch}
                      type={"system"}
                      handleCardClick={() =>
                        handleMatchProfileNavigation(
                          userMatch?.user_matched?.id,
                          userMatch?.user_matched_status,
                          userMatch?.id
                        )
                      }
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="space-y-5 flex flex-col justify-between py-5">
              <div className="space-y-5">
                <img
                  src={Happy}
                  alt="empty"
                  className=" md:hidden w-full h-40 rounded-lg object-cover"
                />
                <div className=" mx-auto hidden w-20 h-20 flex-shrink-0 rounded-full bg-gray-200 md:flex justify-center items-center">
                  <img
                    src={NoUser}
                    alt="empty"
                    className="w-5 md:w-10 h-5 md:h-10 object-contain"
                  />
                </div>
                <h2 className="text-sm sm:text-xl text-center font-semibold text-[#081A51] ">
                  You have no profile suggestion
                </h2>
                <p className="text-xs sm:text-sm w-full pb-5 md:w-3/5 mx-auto text-center">
                  Handler suggestions are matches handpicked by your handler,
                  for you. It is a premium feature and available only to paid
                  users.
                </p>
              </div>
              {/* <div className="flex flex-col gap-7 justify-center items-center">
                <button
                  onClick={() => {
                    navigate("/dashboard/handler-chat");
                  }}
                  className="px-6 text-sm flex gap-2 items-center md:text-base py-2.5 rounded-lg bg-[#081a51d3] text-white font-semibold hover:opacity-75 disabled:bg-gray-300 disabled:text-gray-700"
                >
                  <Chat fontSize="inherit" />
                  Chat with Handler
                </button>
              </div> */}

              <div className="flex flex-col gap-7 justify-center items-center">
                <button
                  onClick={() => {
                    navigate("/dashboard/edit", {
                      state: {
                        page: "edit",
                      },
                    });
                  }}
                  className="px-6 text-sm flex gap-2 items-center md:text-base py-2.5 rounded-lg  bg-[#081a51d3] font-semibold hover:opacity-75 text-white "
                >
                  <Settings fontSize="inherit" />
                  Edit your preference
                </button>
                <p className="text-xs">OR</p>
                <button
                  onClick={() => {
                    navigate("/dashboard/handler-chat");
                  }}
                  className="px-6 text-sm flex gap-2 items-center md:text-base py-2.5 rounded-lg text-[#081a51d3] border-[#081a51d3] border  font-semibold hover:opacity-75 disabled:bg-gray-300 disabled:text-gray-700"
                >
                  <Chat fontSize="inherit" />
                  Chat with Handler
                </button>
              </div>

              <p className="text-xs sm:text-sm w-full pb-5 md:w-3/5 mx-auto text-center">
                Kindly check this space frequently to see all profile
                suggestions made by your handler
              </p>
            </div>
          )}
        </>
      )}

      <Notifications
        visible={showNotifications}
        close={() => setShowNotifications(false)}
        user_id={Number(user?.id)}
      />
    </div>
    //   )}
    // </>
  );
};

export default ProfileSuggestion;
