import { useMutation } from "@apollo/client";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { useMediaQuery } from "@mui/material";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import theme from "src/assets/colors/theme";
import ButtonComp from "src/components/Button/Button";
import EmptyCard from "src/components/Card/emptyCard";
import InputField from "src/components/Input";
import AdminModal from "src/components/Message/AdminModal";
import SingleButton from "src/components/SingleButton";
import { useUser } from "src/components/auth/hooks/UserProvider";
// import { NotificationsObj } from "src/data/notification";
import { Menu, Transition } from "@headlessui/react";
import {
  CreditCard,
  Edit,
  Image,
  LockPerson,
  Person,
  PersonRemove,
} from "@mui/icons-material";
import NoChat from "src/assets/no_chat.png";
import { timeSinceDateFormatted } from "src/helpers";
import { DELETE_ACCOUNT } from "src/hooks/data/mutations";
import ViewImageOverlay from "src/overlays/ViewImagOverlay";
import ASSETS from "src/asset";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import { BeatLoader } from "react-spinners";
import { toast } from "react-toastify";
import moment from "moment";

const tellUs = [
  "Poor handler response",
  "Couldn’t find love",
  "Subscription plans too expensive",
  "I have found love now",
  "Prefer not to say",
  "Other reasons",
];
const Disatisfied =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/disatisfied.svg";
const Delete =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/delete.svg";
const Marvin =
  "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/marvin.svg";

const UserProfile = () => {
  const { user } = useUser();
  const [deleteAcount] = useMutation(DELETE_ACCOUNT);
  const [showModal, setShowModal] = useState(false);
  const [questionDelete, setQuestionDelete] = useState(false);
  const navigate = useNavigate();

  const [openImage, setOpenImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const [selectedOption, setSelectedOption] = useState("");
  const [inputReasonValue, setInputReasonValue] = useState("");
  const [inputReasonValueError, setInputReasonValueError] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [indexPage, setIndexPage] = useState(0);
  const userName = `${user?.first_name} ${user?.last_name}`;

  const closeModal = () => setShowModal(false);

  const toggleQuestionModal = () => {
    setQuestionDelete(!questionDelete);
  };
  const handleOptionChange = (event) => {
    setInputReasonValueError("");
    let selected = event.target.value;
    setSelectedOption(selected);
  };

  const handleDeletAccount = () => {
    setIsLoading(true);
    deleteAcount({
      variables: {
        userId: Number(user?.id),
        reason:
          selectedOption === "Other reasons"
            ? inputReasonValue?.trim?.()
            : selectedOption,
      },
      onCompleted: () => {
        toast.success("Account Deleted");
        setIsLoading(false);
        localStorage.clear();
        backToLogin();
      },
      onError: (err) => {
        setIsLoading(false);
        toast.error(err?.message ?? "Something went wrong, Try again later");
      },
    });
  };

  const backToLogin = () => {
    window.location.replace("/login");
    localStorage.clear();
  };

  return (
    <>
      <ViewImageOverlay
        setIsOpen={setOpenImage}
        size="small"
        isOpen={openImage}
        title={""}
      >
        <div className=" bg-white flex justify-center items-center space-y-3 w-full">
          <div className="bg-gray-200 min-h-[500px] w-full flex justify-center items-center">
            <img
              src={selectedImage ?? ASSETS?.Avi}
              alt="profile"
              style={{
                height: selectedImage ? "500px" : "300px",
                width: "100%",
                backgroundColor: "#eee",
              }}
              className={" object-center md:object-contain"}
            />
          </div>
        </div>
      </ViewImageOverlay>
      <div className="space-y-10">
        <div className="md:pb-24">
          <div
            className={`w-full h-40 sm:h-60 lg:h-[297px] bg-[#0000009e] bg-blend-soft-light
            rounded-lg px-4 py-2 flex justify-between bg-cover bg-center bg-no-repeat relative`}
            style={{
              backgroundImage: `url(${Marvin})`,
            }}
            //@ts-ignore
          >
            {
              <div
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                className="absolute text-6xl text-gray-500 lg:text-9xl z-10 w-max "
              >
                <Image fontSize="inherit" color="inherit" />
              </div>
            }
            <div className=" absolute bottom-0 translate-y-5 gap-2 md:gap-5 lg:translate-y-20  flex ">
              <div
                onClick={() => {
                  setSelectedImage(user?.profile_image_url ?? null);
                  setOpenImage(true);
                }}
                className="lg:w-44 border text-lg lg:text-7xl p-1 flex justify-center items-center border-gray-300 bg-white h-12 w-12 sm:w-20 sm:h-20 lg:h-44 max-lg:w-16 max-lg:h-16 rounded-full bg-cover bg-center bg-no-repeat"
              >
                {!user?.profile_image_url ? (
                  <Person fontSize="inherit" />
                ) : (
                  <img
                    src={user?.profile_image_url}
                    alt="profile"
                    className="h-full w-full object-cover rounded-full"
                  />
                )}
              </div>
              <span className="text-white sm:mt-5 lg:mt-10  max-lg:text-slate-700 md:text-lg font-bold  capitalize whitespace-nowrap text-xs  ">
                {userName}
              </span>
            </div>
            <div className="absolute right-2 sm:right-4 p-0 z-20 bottom-2 md:bottom-5 sm:p-2">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
                <SingleButton
                  icon={
                    <WarningAmberOutlinedIcon
                      style={{
                        color: "#f43f5e",
                        marginRight: "6px",
                        fontSize: isSmallScreen ? "16px" : "21px",
                      }}
                    />
                  }
                  text={
                    user?.userStrike?.length > 1
                      ? `${user?.userStrike?.length} Strikes`
                      : `${user?.userStrike?.length} Strike`
                  }
                  className="flex justify-center p-1.5 sm:px-3 sm:py-1.5 items-center bg-rose-100  rounded-full cursor-pointer"
                  inlineStyle="text-rose-500 lg:text-sm text-[9px] font-semibold"
                  onBtnClick={() => setShowModal(!showModal)}
                />
                <Menu as="div" className="w-full relative">
                  {({ open }) => (
                    <>
                      <Menu.Button as="div" className="">
                        <div className="w-full space-y-2">
                          <div className="focus:outline-none w-full appearance-none rounded-md cursor-pointer border border-gray-300 p-1.5 sm:px-2 sm:py-1.5 flex gap-2 md:gap-5 font-medium justify-between items-center shadow-sm bg-white sm:text-sm capitalize lg:text-lg text-xs">
                            <p className="text-xs sm:text-sm block truncate">
                              Manage Profile
                            </p>
                            <KeyboardArrowDownIcon
                              style={{ fontSize: "inherit" }}
                            />
                          </div>
                        </div>
                      </Menu.Button>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="absolute z-50 p-2 space-y-2 border border-brand-1 overflow-y-auto mt-1 bg-white shadow-lg rounded-lg w-max top-full right-0"
                        >
                          <Menu.Item>
                            <div
                              onClick={() => navigate("/dashboard/edit")}
                              className={`w-full rounded-lg cursor-pointer px-3 py-2 hover:bg-drop-1 text-[#091C52] hover:text-white text-xs sm:text-sm `}
                            >
                              <div className="flex gap-2 items-center">
                                <Edit fontSize="inherit" />
                                <p className=" ">Edit Profile</p>
                              </div>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div
                              onClick={() =>
                                navigate("/dashboard/auth-reset-password")
                              }
                              className={`w-full rounded-lg cursor-pointer px-3 py-2 hover:bg-drop-1 text-[#091C52] hover:text-white text-xs sm:text-sm `}
                            >
                              <div className="flex gap-2 items-center">
                                <LockPerson fontSize="inherit" />
                                <p className=" ">Reset Password</p>
                              </div>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div
                              onClick={() =>
                                navigate("/dashboard/choose-a-plan")
                              }
                              className={`w-full rounded-lg cursor-pointer px-3 py-2 hover:bg-drop-1 text-[#091C52] hover:text-white text-xs sm:text-sm `}
                            >
                              <div className="flex gap-2 items-center">
                                <CreditCard fontSize="inherit" />
                                <p className=" ">Subscriptions</p>
                              </div>
                            </div>
                          </Menu.Item>
                          <Menu.Item>
                            <div
                              onClick={toggleQuestionModal}
                              className={`w-full rounded-lg cursor-pointer px-3 py-2 hover:bg-drop-1 text-[#091C52] hover:text-white text-xs sm:text-sm `}
                            >
                              <div className="flex gap-2 items-center">
                                <PersonRemove fontSize="inherit" />
                                <p className=" ">Delete Account</p>
                              </div>
                            </div>
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div className=" bg-white divide-y divide-gray-300 shadow-md rounded-lg">
          <p className="font-bold p-4 capitalize text-base">About Me</p>
          <div className="p-4 w-full ">
            {user?.about_me ? (
              <p className="text-sm font-medium whitespace-normal break-words">
                {user?.about_me}
              </p>
            ) : (
              <p className="text-base italic font-medium ">
                No bio/about available
              </p>
            )}
          </div>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 xl:gap-5">
          <EmptyCard header="Personal Information">
            <div className="p-4 space-y-2 pb-0">
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Full name:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.first_name ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Username:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.alais ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Date of Birth:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.age?.includes?.("/")
                    ? moment(user?.age, "DD/MM/YYYY").format("D MMMM, YYYY")
                    : moment(new Date(user?.age)).format("D MMMM, YYYY") ??
                      "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Gender:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.gender ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Genotype:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.genotype ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Phone Number:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.phone ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Email:{" "}
                <span className="text-sm font-normal">
                  {user?.email?.toLowerCase?.() ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Etnicity:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.ethnicity ?? "N/A"}
                </span>
              </p>
            </div>
          </EmptyCard>
          <EmptyCard header="Other Details">
            <div className="p-4 space-y-2 pb-0">
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Religion:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.religion ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Occupation:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.occupation ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Location:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.location_country ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Place of birth:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.origin_country ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Highest Academic Qualification:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.qualification ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Relationship Status:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.status ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center max-w-xs xl:max-w-sm">
                Hobbies:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.hobbies ?? "N/A"}
                </span>
              </p>
            </div>
          </EmptyCard>
          <EmptyCard header="Preferences">
            <div className="p-4 space-y-2 pb-0">
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Preferred Gender:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.preferred_gender ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Preferred age range:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.preferred_age ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Preferred Genotype:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.preferred_genotype ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Preferred Religion:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.preferred_religion ?? "N/A"}
                </span>
              </p>
              <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Preferred Relationship:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.preferred_status ?? "N/A"}
                </span>
              </p>
               <p className="font-semibold text-xs flex gap-1 flex-wrap items-center">
                Preferred Countries:{" "}
                <span className="text-sm font-normal capitalize">
                  {user?.preferred_country?? "N/A"}
                </span>
              </p>
            </div>
          </EmptyCard>
        </div>
        <div className="w-full p-4 rounded-lg shadow-md bg-white">
          <p className="font-bold text-base">My Photos</p>
          {user?.media?.length > 0 ? (
            <div className="w-full py-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5">
              {user?.media?.map?.((photo: any, i: number) => (
                <img
                  key={i}
                  onClick={() => {
                    setSelectedImage(photo?.url);
                    setOpenImage(true);
                  }}
                  className={`h-40 border border-gray-600 object-cover rounded-lg w-full`}
                  src={photo?.url}
                  alt="match"
                />
              ))}
            </div>
          ) : (
            <div className=" items-center justify-center w-full h-60 bg-white flex">
              <div className=" flex gap-5 flex-col justify-center items-center">
                <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                  <img
                    src={NoChat}
                    alt="empty"
                    className="w-5 md:w-10 h-5 md:h-10 object-contain"
                  />
                </div>
                <h2 className="text-lg text-center font-bold text-[#081A51] ">
                  No Photos available
                </h2>
                <p className="text-sm w-4/5 mx-auto text-center">
                  This user doesn't have any photo in their media
                </p>
              </div>
            </div>
          )}
        </div>
      </div>

      <ResponsiveOverlay
        isOpen={questionDelete}
        setIsOpen={() => {
          setQuestionDelete(false);
          setIndexPage(0);
          setInputReasonValue("");
          setSelectedOption("");
        }}
        title={""}
        size="small"
      >
        <div className="h-full w-full">
          <div className="w-full gap-5 mx-auto p-5 pt-10 bg-white flex flex-col justify-center items-center">
            {indexPage === 0 ? (
              <>
                <div className="w-20 h-20 flex-shrink-0 rounded-full flex justify-center items-center bg-gray-200">
                  <img src={Delete} className="w-10 h-10" alt="unmatch" />
                </div>
                <div className="space-y-2">
                  <p className="text-[#081A51] text-lg font-bold text-center">
                    You are about to delete your account with us
                  </p>
                  <p className="text-[#223333] text-sm text-center">
                    All your information and on going subscriptions on our
                    platform will be removed from our data base and you will no
                    longer have access to our platform except you reregister as
                    a new user
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className=" flex gap-5 items-center">
                  <img
                    src={Disatisfied}
                    className="w-10 h-10 rounded-full"
                    alt={"Disatisfied"}
                  />
                  <img
                    src={Disatisfied}
                    className="w-10 h-10 rounded-full"
                    alt={"Disatisfied"}
                  />
                  <img
                    src={Disatisfied}
                    className="w-10 h-10 rounded-full"
                    alt={"Disatisfied"}
                  />
                </div>
                <div className="">
                  <p className="text-[#081A51] font-semibold text-center">
                    Please tell us what went wrong
                  </p>
                  <p className="text-[#223333] text-center text-xs">
                    Please select an option that best suits your response. Your
                    feedback is confidential and will help us create a better
                    experience for other users
                  </p>
                </div>
                <div className="">
                  {tellUs?.map((tell, i) => (
                    <div
                      className="py-1.5 text-left text-[#344054] text-sm"
                      key={i}
                    >
                      <label>
                        <input
                          type="radio"
                          value={tell}
                          checked={selectedOption === tell}
                          onChange={handleOptionChange}
                          className="mr-2"
                        />
                        {tell}
                      </label>
                    </div>
                  ))}
                  {selectedOption === "Other reasons" && (
                    <input
                      type="text"
                      className={`text-sm outline-none border ${
                        inputReasonValueError
                          ? "border-red-600"
                          : "border-gray-400"
                      } mt-2 w-72 rounded-lg`}
                      placeholder="Kindly enter a reason"
                      onChange={(e) => {
                        setInputReasonValue(e.target.value);
                        setInputReasonValueError("");
                      }}
                      value={inputReasonValue}
                    />
                  )}
                </div>
                {inputReasonValueError && (
                  <p className="text-[9px] sm:text-xs text-red-600">
                    {inputReasonValueError}
                  </p>
                )}
              </>
            )}
          </div>
          <div className="flex p-5 w-full justify-end gap-5 bg-gray-50 items-center">
            {!isLoading && (
              <button
                onClick={() => {
                  if (indexPage === 1) {
                    setSelectedOption("");
                    setInputReasonValue("");
                    return setIndexPage(0);
                  }
                  return setQuestionDelete(false);
                }}
                className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-white border border-gray-400 text-gray-700"
              >
                {indexPage === 0 ? "No, Do Not" : "Back"}
              </button>
            )}
            <button
              disabled={isLoading}
              onClick={() => {
                if (indexPage === 0) {
                  return setIndexPage(1);
                }
                if (!selectedOption) {
                  return setInputReasonValueError("Kindly choose an option");
                }
                if (
                  selectedOption === "Other reasons" &&
                  inputReasonValue?.trim?.() === ""
                ) {
                  return setInputReasonValueError("Kindly add an reason");
                }
                return handleDeletAccount();
              }}
              className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-[#081A51] text-white"
            >
              {isLoading ? (
                <BeatLoader size={10} color="#fff" />
              ) : indexPage === 0 ? (
                "Yes Continue"
              ) : (
                "Delete"
              )}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>

      <AdminModal
        visible={showModal}
        close={closeModal}
        header="bg-[#081A51] w-full py-3 flex px-6 justify-between items-center mt-20"
        headerText="Strikes issued by handler"
        containerStyle="h-0 lg:w-[50%] w-[90%]"
      >
        <div className="w-full  m-auto flex flex-col flex-grow justify-between lg:h-[450px] h-[850px] bg-white">
          <div className="w-full h-full p-5 overflow-y-auto ">
            {user?.userStrike?.length > 0 ? (
              user?.userStrike?.map((uS, i) => (
                // console.log(i, "<><><>")
                <StrikeMessage
                  strike={uS}
                  handler_name={user?.handler?.user_name}
                  key={uS?.id}
                  className={`flex justify-between w-full p-4 ${
                    i < 2 ? "border-l-2 border-slate-400" : ""
                  } h-32 relative `}
                />
              ))
            ) : (
              <div className=" flex h-full gap-5 flex-col justify-center items-center">
                <div className="w-10 md:w-20 h-10 md:h-20 flex-shrink-0 rounded-full bg-gray-200 flex justify-center items-center">
                  <img
                    src={NoChat}
                    alt="empty"
                    className="w-5 md:w-10 h-5 md:h-10 object-contain"
                  />
                </div>
                <h2 className="text-lg text-center font-bold text-[#081A51] ">
                  No Strike available
                </h2>
              </div>
            )}
          </div>
          <p className="text-[#A303A0] p-2  border-t border-gray-300 flex-shrink-0 font-semibold text-center text-xs lg:text-sm ">
            Please be adviced that after 3 strikes your account will be
            suspended from our platform
          </p>
        </div>
      </AdminModal>
    </>
  );
};

export default UserProfile;

const StrikeMessage = ({ strike, handler_name, className = "" }) => {
  return (
    <div
      className={className || `flex justify-between w-full h-32 max-lg:h-52 `}
    >
      <div className="border-2 w-4 h-4 rounded-full border-[#D4678F] absolute top-[-6px] left-[-9px] bg-white"></div>
      <div className="flex flex-col absolute top-[-6px] mb-8">
        <p className="text-[#222E50] font-semibold text-sm">
          Handler: {handler_name}
        </p>
        <p className="text-[#343D48] text-xs lg:leading-6 leading-5">
          {strike?.reason}
        </p>
      </div>
      <p className="w-12 h-5 rounded-md lg:text-xs bg-[#f8d9df] text-[#FE5578] flex justify-center items-center text-[9px] p-1 right-[10px] top-[-6px] absolute">
        {timeSinceDateFormatted(strike?.created_at)}
      </p>
    </div>
  );
};
