import { KeyboardBackspace } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import Cancel from "src/assets/cancel.svg";
import Mark from "src/assets/mark.svg";
import SingleButton from "src/components/SingleButton";
import { useCurrency } from "src/context/AmountProvider";

const ChoosePlan = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    waitForAnimate: false,
    cssEase: "linear",
    swipeToSlide: true,
    pauseOnHover: true,
    // appendDots: (dots) => (
    //   <div
    //     style={{
    //       borderRadius: "10px",
    //       padding: "10px",
    //       top: "-24px",
    //       height: "5px",
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //     }}
    //   >
    //     <ul style={{ margin: "0px" }}> {dots} </ul>
    //   </div>
    // ),
    // customPaging: (i) => (
    //   <div
    //     style={{
    //       display: "flex",
    //       justifyContent: "center",
    //       alignItems: "center",
    //       width: "5px",
    //       height: "5px",
    //       borderRadius: "50%",
    //       backgroundColor: "red",
    //     }}
    //   >
    //     <p
    //       style={{
    //         width: "5px",
    //         height: "5px",
    //         borderRadius: "50%",
    //         color: "blue",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //         backgroundColor: "#081A51",
    //         fontSize: "4px",
    //       }}
    //     >
    //       {i}
    //     </p>
    //   </div>
    // ),
    // nextArrow: <LeftArrow />,
    // prevArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const plans = ["Monthly", "Quarterly", "Bi-annually", "Annually"];
  const [amount, setAmount] = useState("200");
  const {
    // eliteMonth,
    // eliteBi,
    // eliteYear,
    premiumMonth,
    premiumBi,
    premiumYear,
    premiumQuater,
    // premiumBlackMonth,
    // premiumBlackBi,
    // premiumBlackYear,
  } = useCurrency();

  const navigate = useNavigate();

  const [singlePlan, setSinglePlan] = useState("Annually");

  const handleChoosePlan = (i) => {
    setSinglePlan(i);
    if (i === "Annually") {
      setAmount("200");
    }
    if (i === "Quarterly") {
      setAmount("90");
    }
    if (i === "Monthly") {
      setAmount("40");
    }
    if (i === "Bi-annually") {
      setAmount("120");
    }
  };

  return (
    <div className="bg-white overflow-x-hidden w-full h-screen">
      <div className="grid grid-cols-1 h-full sm:grid-cols-1 overflow-x-hidden md:grid-cols-2  items-start p-4 gap-5 sm:p-6 lg:p-10 w-full">
        <div className="space-y-4 flex flex-col justify-center py-4 w-full">
          <h2
            onClick={() => {
              navigate(-1);
            }}
            className="text-sm sm:text-xl w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
          >
            <KeyboardBackspace fontSize="inherit" />
            Back
          </h2>
          <p className="text-[#081A51] text-2xl font-semibold sm:text-4xl lg:text-7xl ">
            Our membership plans are tailored to suit your budget
          </p>
          <p className="text-[#223333] pb-3 text-xs sm:text-sm text-justify">
            Find love without breaking the bank with our flexible membership
            plans. We offer affordable options tailored to suit any budget,
            providing diverse features for meaningful connections. Please note:
            Nirvanae is for individuals aged 21 and over, seeking committed
            relationships. We prefer to work with individuals who are
            professionals in whatever field they find themselves. If it is
            discovered that you do not fit in with our mission, you would be
            blacklisted.
          </p>
          <div className=" bg-[#E8EDFB] p-1.5 justify-between w-full overflow-x-auto rounded-full flex items-center gap-2">
            {plans.map((plan) => (
              <div
                onClick={() => handleChoosePlan(plan)}
                className={`text-xs sm:text-sm flex items-center justify-center ${
                  singlePlan === plan ? "bg-[#081A51] text-white" : ""
                } py-1 px-2.5 rounded-full flex-shrink-0 cursor-pointer`}
              >
                <p className="font-semibold">{plan}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="slider-container px-5 lg:hidden pb-10">
          <Slider {...settings}>
            {/* premium mobible */}
            <div className="py-3">
              <div className=" max-md:p-2 p-4 rounded-xl bg-[#081A51]">
                <p className="text-white text-2xl font-semibold">
                  Premium Tier
                </p>
                <p className="mt-4 text-white">
                  Ideal for individuals who need to access our advanced features
                  and personalize their dating experience{" "}
                </p>
                <div className="flex items-center mt-8">
                  <p className="text-2xl font-semibold text-white mr-1 max-md:text-[11px]">
                    &#8358; {amount},000{" "}
                  </p>
                  <p className="text-white text-xs"> / {singlePlan}</p>
                </div>
                <SingleButton
                  className="border mt-10 py-2 rounded-sm bg-[#FFFFFF] cursor-pointer"
                  text="Get Started Now"
                  inlineStyle="text-center text-[#081A51]"
                  onBtnClick={() =>
                    navigate("/get-started/premium", {
                      state: {
                        currency_code: PlanAmountType(
                          singlePlan,
                          premiumMonth,
                          premiumBi,
                          premiumYear,
                          premiumQuater,
                          1
                        ),
                        plan_cost: PlanAmountType(
                          singlePlan,
                          premiumMonth,
                          premiumBi,
                          premiumYear,
                          premiumQuater,
                          0
                        ),
                        plan_duration: singlePlan,
                      },
                    })
                  }
                />
               {singlePlan === "Annually" && <div>
<div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new suggestions in Discover daily. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get at least one(1) personalized match from your handler, monthly.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Daily, receive more precise suggestions in Handler’s Suggestions, 50 percent of which are on premium annually. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive other suggestions from members on other membership levels to boost your search. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Full handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Get after match support.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get set up on personalized Blind dates by Handler. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Upon your request, schedule one(1) therapy session with a professional clinical psychologist.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Receive a full report that informs you about you, and the kind of partners you should be looking out for within our private matchmaking community.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Receive a full report on any match you want to make, including match history, strike history, blacklist report- and any other thing you’ll like to know about a potential match, within our knowledge.
                  </p>
                </div>
               </div>  
              }
                 {singlePlan === "Bi-annually" && <div>
<div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get at least one(1) personalized match from your handler, monthly.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Daily, receive more precise suggestions in Handler’s Suggestions, 50 percent of which are on premium annually. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive other suggestions from members on other membership levels to boost your search. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Full handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Get after match support.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get set up on personalized Blind dates by Handler. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No feedback on potential matches. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No scheduled therapy sessions with psychologist.
                  </p>
                </div>
               
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No access to therapy reports.
                  </p>
                </div>
               </div>  
              }
               {singlePlan === "Quarterly" && <div>
<div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get one(1) personalized match from your handler, monthly.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Daily, receive more precise suggestions in Handler’s Suggestions, 75 percent of which are other Premium Quarterly subscribers. 
                  </p>
                </div>
                
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Full handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Get after match support.  
                  </p>
                </div>
                
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No access to personalized Blind dates by Handler. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No scheduled therapy sessions with psychologist.
                  </p>
                </div>
               
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No access to therapy reports.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No feedback on potential matches.
                  </p>
                </div>
               </div>  
              }
               {singlePlan === "Monthly" && <div>
<div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail. 
                  </p>
                </div>
               
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Daily, receive more precise suggestions in Handler’s Suggestions, 90 percent of which are other monthly subscribers.  
                  </p>
                </div>
                
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Full handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Get after match support.  
                  </p>
                </div>
                
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No access to Blind dates by Handler.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No scheduled therapy sessions with psychologist.
                  </p>
                </div>
               
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No access to therapy reports.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No feedback on potential matches.
                  </p>
                </div>
               </div>  
              }
              </div>
            </div>
            {/* free  mobile*/}
            <div className="p-3">
              <div className=" max-md:p-2 p-4 rounded-xl border border-[#98A2B3]">
                <p className="text-[#081A51] text-2xl font-semibold">
                  Free Tier
                </p>
                <p className="mt-4 text-[#223333]">
                  Ideal for individuals who need a quick access to basic
                  features with an idea of exploring our platofrm
                </p>
                <div className="flex items-center mt-8">
                  <p className="text-2xl font-semibold text-[#081A51] mr-1 max-md:text-[11px]">
                    &#8358; 0{" "}
                  </p>
                  <p className="text-[#223333] text-xs"> / {singlePlan}</p>
                </div>
                <SingleButton
                  className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
                  text="Get Started Now"
                  inlineStyle="text-center text-[#081A51]"
                  //@ts-ignore
                  onBtnClick={() => {
                    navigate("/get-started/basic");
                  }}
                />
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail.  
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    No access to matches with more precision in Handler’s suggestions. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                   No personalized matching by handler
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    No matching with private subscribers. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                   Limited handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    No access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    No access to personalized Blind dates by Handler. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    No after match support.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    No scheduled therapy sessions. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                   No access to therapy reports. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                   No feedback on potential matches. 
                  </p>
                </div>
                {/* <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-[#223333] text-sm max-md:text-[11px]">
                    Handler unlocked for all your questions, complaints and for
                    direct referrals
                  </p>
                </div> */}
              </div>
            </div>
          </Slider>
        </div>
        <div className="p-4 lg:grid hidden grid-cols-2 gap-5">
          <div className=" max-md:p-2 p-4 rounded-xl bg-[#081A51]">
            <p className="text-white text-2xl font-semibold">Premium Tier</p>
            <p className="mt-4 text-white">
              Ideal for individuals who need to access our advanced features and
              personalize their dating experience{" "}
            </p>
            <div className="flex items-center mt-8">
              <p className="text-2xl font-semibold text-white mr-1 max-md:text-[11px]">
                &#8358; {amount},000{" "}
              </p>
              <p className="text-white text-xs"> / {singlePlan}</p>
            </div>
            <SingleButton
              className="border mt-10 py-2 rounded-sm bg-[#FFFFFF] cursor-pointer"
              text="Get Started Now"
              inlineStyle="text-center text-[#081A51]"
              onBtnClick={() =>
                navigate("/get-started/premium", {
                  state: {
                    currency_code: PlanAmountType(
                      singlePlan,
                      premiumMonth,
                      premiumBi,
                      premiumYear,
                      premiumQuater,
                      1
                    ),
                    plan_cost: PlanAmountType(
                      singlePlan,
                      premiumMonth,
                      premiumBi,
                      premiumYear,
                      premiumQuater,
                      0
                    ),
                    plan_duration: singlePlan,
                  },
                })
              }
            />
                      {singlePlan === "Annually" && <div>
<div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new suggestions in Discover daily. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get at least one(1) personalized match from your handler, monthly.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Daily, receive more precise suggestions in Handler’s Suggestions, 50 percent of which are on premium annually. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive other suggestions from members on other membership levels to boost your search. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Full handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Get after match support.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get set up on personalized Blind dates by Handler. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Upon your request, schedule one(1) therapy session with a professional clinical psychologist.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Receive a full report that informs you about you, and the kind of partners you should be looking out for within our private matchmaking community.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Receive a full report on any match you want to make, including match history, strike history, blacklist report- and any other thing you’ll like to know about a potential match, within our knowledge.
                  </p>
                </div>
               </div>  
              }
                 {singlePlan === "Bi-annually" && <div>
<div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get at least one(1) personalized match from your handler, monthly.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Daily, receive more precise suggestions in Handler’s Suggestions, 50 percent of which are on premium annually. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive other suggestions from members on other membership levels to boost your search. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Full handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Get after match support.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get set up on personalized Blind dates by Handler. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No feedback on potential matches. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No scheduled therapy sessions with psychologist.
                  </p>
                </div>
               
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No access to therapy reports.
                  </p>
                </div>
               </div>  
              }
               {singlePlan === "Quarterly" && <div>
<div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Upon your request, get one(1) personalized match from your handler, monthly.
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Daily, receive more precise suggestions in Handler’s Suggestions, 75 percent of which are other Premium Quarterly subscribers. 
                  </p>
                </div>
                
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Full handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Get after match support.  
                  </p>
                </div>
                
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No access to personalized Blind dates by Handler. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No scheduled therapy sessions with psychologist.
                  </p>
                </div>
               
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No access to therapy reports.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No feedback on potential matches.
                  </p>
                </div>
               </div>  
              }
               {singlePlan === "Monthly" && <div>
<div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="mark" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily. 
                  </p>
                </div>
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail. 
                  </p>
                </div>
               
                <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Daily, receive more precise suggestions in Handler’s Suggestions, 90 percent of which are other monthly subscribers.  
                  </p>
                </div>
                
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Full handler support. 
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                   Access to system scheduled Blind Dates.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Mark} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                    Get after match support.  
                  </p>
                </div>
                
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No access to Blind dates by Handler.  
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                No scheduled therapy sessions with psychologist.
                  </p>
                </div>
               
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No access to therapy reports.
                  </p>
                </div>
                 <div className="flex mt-10">
                  <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                  <p className="text-white text-sm max-md:text-[11px]">
                  No feedback on potential matches.
                  </p>
                </div>
               </div>  
              }
          </div>
          <div className=" max-md:p-2 p-4 rounded-xl border border-[#98A2B3]">
            <p className="text-[#081A51] text-2xl font-semibold">Free Tier</p>
            <p className="mt-4 text-[#223333]">
              Ideal for individuals who need a quick access to basic features
              with an idea of exploring our platofrm
            </p>
            <div className="flex items-center mt-8">
              <p className="text-2xl font-semibold text-[#081A51] mr-1 max-md:text-[11px]">
                &#8358; 0{" "}
              </p>
              <p className="text-[#223333] text-xs"> / {singlePlan}</p>
            </div>
            <SingleButton
              className="border border-[#081A51] mt-10 py-2 rounded-sm cursor-pointer"
              text="Get Started Now"
              inlineStyle="text-center text-[#081A51]"
              onBtnClick={() => navigate("/get-started/basic")}
            />
            <div className="flex mt-10">
              <img src={Mark} className="h-6 mr-4" alt="mark" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
               View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily. 
              </p>
            </div>
              <div className="flex mt-10">
              <img src={Mark} className="h-6 mr-4" alt="mark" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
              Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail. 
              </p>
            </div>
            <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
               No access to matches with more precision in Handler’s suggestions. 
              </p>
            </div>
            <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
               No personalized matching by handler.
              </p>
            </div>
            <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                No matching with private subscribers. 
              </p>
            </div>
             <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                Limited handler support.  
              </p>
            </div>
             <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                No access to system scheduled Blind Dates.
              </p>
            </div>
             <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                No access to personalized Blind dates by Handler. 
              </p>
            </div>
             <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
               No after match support.  
              </p>
            </div>
             <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
               No scheduled therapy sessions.  
              </p>
            </div>
             <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                No access to therapy reports. 
              </p>
            </div>
             <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
               No feedback on potential matches.
              </p>
            </div>
            {/* <div className="flex mt-10">
              <img src={Cancel} className="h-6 mr-4" alt="cancel" />
              <p className="text-[#223333] text-sm max-md:text-[11px]">
                Handler unlocked for all your questions, complaints and for
                direct referrals
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChoosePlan;

const PlanAmountType = (
  plan: string,
  month: string,
  bi_annual: string,
  annual: string,
  quarter: string,
  index: number
) => {
  switch (plan) {
    case "Bi-annually":
      return `${bi_annual.split("-")[index]}`;

    case "Annually":
      return `${annual.split("-")[index]}`;

    case "Monthly":
      return `${month.split("-")[index]}`;

    case "Quarterly":
      return `${quarter.split("-")[index]}`;

    default:
      return ``;
  }
};
function LeftArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        right: 0,
        width: "40px",
        height: "40px",
        display: "flex",
        border: "2px solid black",
        zIndex: 60,
        background: "#000",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        borderRadius: 8,
      }}
      onClick={onClick}
    ></div>
  );
}
function RightArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        left: 0,
        width: "40px",
        height: "40px",
        display: "flex",
        border: "2px solid black",
        zIndex: 60,
        background: "#000",
        justifyContent: "center",
        alignItems: "center",
        color: "black",
        borderRadius: 8,
      }}
      onClick={onClick}
    ></div>
  );
}

function dotPositionTop(dots) {
  return (
    <div
      style={{
        borderRadius: "10px",
        padding: "10px",
        top: "-24px",
        height: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ul style={{ margin: "0px" }}> {dots} </ul>
    </div>
  );
}
const customPaging = (i) => {
  return (
    <div
      style={{
        width: "30px",
        color: "blue",
        border: "1px blue solid",
      }}
    >
      {i + 1}
    </div>
  );
};
