import { useMutation, useQuery } from "@apollo/client";
import {
  AssignmentInd,
  CreditCard,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  KeyboardBackspace,
} from "@mui/icons-material";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import BuyPlan from "src/assets/buy-plan.svg";
import Mark from "src/assets/mark.svg";
import PayMark from "src/assets/payment-mark.svg";
import Cancel from "src/assets/cancel.svg";
import {
  PAYMENT_SUCCESS,
  UPDATE_USER,
} from "src/components/auth/data/mutation";
import { useUser } from "src/components/auth/hooks/UserProvider";
import PaystackPortal from "src/components/paystack/PaystackPortal";
import { calculateTimeLefttillNextPayment } from "src/helpers/dateTime";
import { CANCEL_PAYSTACK_SUB } from "src/hooks/data/mutations";
import { PAYSTACK_SUB_FILTER } from "src/hooks/data/queries";
import { Menu, Transition } from "@headlessui/react";
import ResponsiveOverlay from "src/overlays/ResponsiveOverlay";
import { BeatLoader } from "react-spinners";
import PageLoader from "src/components/PageLoader";
import moment from "moment";
import Loading from "src/components/Loading";
import { useCurrency } from "src/context/AmountProvider";
import { GENERICPLANSNG, GENERICPLANSUSD } from "src/helpers/constants";

const RevisedAuthPlan = () => {
  const { user } = useUser();
  const [paymentSuccess] = useMutation(PAYMENT_SUCCESS);
  const { country } = useCurrency();
  const Delete =
    "https://nirvanaemedia.s3.amazonaws.com/assets/Web+Assets/assets/delete.svg";

  const {
    data: paystackFilter,
    error,
    loading,
    refetch,
  } = useQuery(PAYSTACK_SUB_FILTER, {
    variables: {
      active: true,
      userId: Number(user?.id),
    },
    fetchPolicy: "no-cache",
  });

  const [cancelPaystack] = useMutation(CANCEL_PAYSTACK_SUB);
  const [updateUser] = useMutation(UPDATE_USER);
  const [currency, setCurrency] = useState(
    country?.trim?.() === "nigeria" ? "₦" : "$"
  );
  const [amount, setAmount] = useState(
    country?.trim?.() === "nigeria" ? "200000" : "200"
  );
  const [discount, setDiscount] = useState("100000");
  const [singlePlan, setSinglePlan] = useState("annually");
  const [successfullPayment, setSuccessfullPayment] = useState(false);
  const [step, setStep] = useState(1);
  const [user_reference, setUserReference] = useState<string>(
    `REFSUB${Date.now().toString()}`
  );
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isViewDetails, setIsViewDetails] = useState(false);
  const [isLoadingPayment, setIsLoadingPayment] = useState(false);

  const handleChoosePlan = (passedplan) => {
    setSinglePlan(passedplan?.plan);
    setAmount(passedplan?.amount);
    setUserReference(`REFSUB${Date.now().toString()}`);
  };
  const handleDiscountChange = (p) => {
    setDiscount(p.discPrice);
    setSinglePlan(p.duration);
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const transformPrice = (price) => {
    if (price === "100000") {
      return "100,000";
    }
    if (price === "45000") {
      return "45,000";
    }
    if (price === "60000") {
      return "60,000";
    }
    if (price === "19500") {
      return "19,500";
    }
  };

  function calculateExpirationDate(paymentDate: any, period: any) {
    const date = new Date(paymentDate);
    let expirationDate;

    switch (period.toLowerCase()) {
      case "annually":
        expirationDate = new Date(date.setFullYear(date.getFullYear() + 1));
        break;
      case "annually-usd":
        expirationDate = new Date(date.setFullYear(date.getFullYear() + 1));
        break;
      case "yearly":
        expirationDate = new Date(date.setFullYear(date.getFullYear() + 1));
        break;

      case "monthly":
        expirationDate = new Date(date.setMonth(date.getMonth() + 1));
        break;
      case "monthly-usd":
        expirationDate = new Date(date.setMonth(date.getMonth() + 1));
        break;

      case "bi-annually":
        expirationDate = new Date(date.setMonth(date.getMonth() + 6));
        break;
      case "bi-annually-usd":
        expirationDate = new Date(date.setMonth(date.getMonth() + 6));
        break;

      case "quarterly":
        expirationDate = new Date(date.setMonth(date.getMonth() + 3));
        break;
      case "quarterly-usd":
        expirationDate = new Date(date.setMonth(date.getMonth() + 3));
        break;

      default:
        return (expirationDate = new Date());
    }
    const now = new Date();
    if (expirationDate < now) {
      return "expired";
    }

    return expirationDate.toISOString();
  }

  const cancelSubscription = () => {
    const py_token = paystackFilter["paystackSubsWhere"][0].token;
    const py_sub_id = paystackFilter["paystackSubsWhere"][0].id;
    const py_subscription_code =
      paystackFilter["paystackSubsWhere"][0].subscription_code;
    const py_subscription_id =
      paystackFilter["paystackSubsWhere"][0].subscription_id;
    setIsLoading(true);
    cancelPaystack({
      variables: {
        payload: {
          token: py_token,
          sub_id: Number(py_sub_id),
          subscription_id: py_subscription_id,
          subscription_code: py_subscription_code,
        },
      },
      onCompleted: (data) => {
        setIsLoading(false);
        if (data?.cancelPaystack === false) {
          toast.error("Unale to cancel, kindly contact support!");
          return;
        }
        setOpenConfirm(false);
        toast.success("Successfully Cancelled Subscription");
        navigate("/dashboard/my-profile");
      },
      onError: (errorCancelPaystack) => {
        setIsLoading(false);
        toast.error(errorCancelPaystack?.message ?? "Network error");
      },
    });
  };

  const paymentSuccessHandler = (payload) => {
    const { plan, amount, email, tx_ref, transaction_id } = payload;
    setIsLoadingPayment(true);
    paymentSuccess({
      variables: {
        payload: {
          amount,
          email,
          plan: plan,
          plan_duration: country?.trim?.() === "nigeria" ? plan : plan + "-usd",
          transaction_id,
          tx_ref,
        },
      },
      onCompleted: (data) => {
        setIsLoadingPayment(false);
        setSuccessfullPayment(true);
      },
      onError: (error) => {
        setIsLoadingPayment(false);
        console.log(error);
      },
    });
  };

  const planFromPayFilter = paystackFilter?.["paystackSubsWhere"]?.[0]?.plan;
  const mainPaystack = user?.paystackSubscription?.filter?.(
    (i) => i?.plan === planFromPayFilter
  )?.[0];

  function calculateDaysLeft(paymentDateString) {
    const paymentDate = new Date(paymentDateString);
    const currentDate = new Date();

    //@ts-ignore
    const differenceInTime = paymentDate - currentDate;

    const daysLeft = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    return daysLeft;
  }

  const mainPlan =
    user?.mode === "paystack"
      ? paystackFilter?.["paystackSubsWhere"]?.[0]?.plan ?? user?.plan
      : user?.plan;

  const majorPlans =
    country?.trim?.() === "nigeria" ? GENERICPLANSNG : GENERICPLANSUSD;

    console.log("see main plan", mainPlan)
  return (
    <>
      <ResponsiveOverlay
        isOpen={openConfirm}
        setIsOpen={setOpenConfirm}
        title={""}
        size="small"
      >
        <div className="h-full w-full">
          <div className="w-full gap-5 mx-auto p-5 pt-10 bg-white flex flex-col justify-center items-center">
            <>
              <div className="w-20 h-20 flex-shrink-0 rounded-full flex justify-center items-center bg-gray-200">
                <img src={Delete} className="w-10 h-10" alt="unmatch" />
              </div>
              <div className="space-y-2">
                <p className="text-[#081A51] text-lg font-bold text-center">
                  You are about to cancel your paid subscription!
                </p>
                <p className="text-[#223333] text-sm text-center">
                  You will be reverted to a basic user and will no longer have
                  access to some features on the platform. <br />
                  Do you wish to continue?
                </p>
              </div>
            </>
          </div>
          <div className="flex p-5 w-full justify-end gap-5 bg-gray-50 items-center">
            {!isLoading && (
              <button
                onClick={() => {
                  setOpenConfirm(false);
                }}
                className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-white border border-gray-400 text-gray-700"
              >
                {"Cancel"}
              </button>
            )}
            <button
              disabled={isLoading}
              onClick={() => {
                cancelSubscription();
              }}
              className="px-4 sm:px-6 py-2 rounded-lg text-center text-xs sm:text-base font-semibold bg-[#081A51] text-white"
            >
              {isLoading ? <BeatLoader size={10} color="#fff" /> : "Continue"}
            </button>
          </div>
        </div>
      </ResponsiveOverlay>

      <div className="overflow pt-5 space-y-5">
        {/* <h2
          onClick={() => {
            navigate(-1);
          }}
          className="text-sm w-max flex gap-2 items-center cursor-pointer hover:text-blue-600 font-bold text-[#1C1F37]"
        >
          <KeyboardBackspace fontSize="small" />
          Back to profile
        </h2> */}
        {isLoadingPayment && <Loading />}
        {loading ? (
          <PageLoader noMargin />
        ) : successfullPayment ? (
          <div className="w-full bg-white">
            <div className="w-full flex p-5 py-10 gap-5  flex-col justify-center items-center">
              <div className="w-12 lg:w-24 h-12 lg:h-24 rounded-full bg-[#F5F5F5] flex justify-center items-center shadow-md ">
                <img
                  src={PayMark}
                  alt="empty"
                  className="w-8 lg:w-12 h-8 lg:h-12 object-contain"
                />
              </div>
              <p className=" font-semibold text-[#081A51] text-sm sm:text-base lg:text-2xl text-center">
                Payment Successful
              </p>
              <p className="w-full text-xs sm:text-sm md:w-3/4 text-center">
                Your payment of{" "}
                {country?.trim?.() === "nigeria" ? "NGN" : "USD"} {amount} for
                the {singlePlan?.toLocaleLowerCase()} premium subscription has
                been processed successfully. Thanks for trusting us to manage
                your online dating experience. You can can start enjoying the
                premium features of our app
              </p>
              <button
                onClick={() => {
                  setSuccessfullPayment(false);
                  refetch();
                }}
                className="w-full sm:w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
              >
                Continue
              </button>
            </div>
          </div>
        ) : (
          <>
            {user?.subscription?.name === "basic" ? (
              step === 1 ? (
                <div className="w-full bg-white ">
                  <div className="w-full flex p-5 gap-5 py-10 flex-col justify-center items-center">
                    <div className="w-12 lg:w-24 h-12 lg:h-24 rounded-full bg-[#F5F5F5] flex justify-center items-center shadow-md">
                      <img
                        src={BuyPlan}
                        alt="empty"
                        className="w-8 lg:w-12 h-8 lg:h-12 rounded-full object-cover"
                      />
                    </div>
                    <p className=" font-semibold text-[#081A51] text-sm sm:text-base lg:text-2xl text-center">
                      You are currently on the free plan
                    </p>
                    <p className="w-full text-xs sm:text-sm md:w-3/4 text-center">
                      You have no access to personalized recommendations from
                      Handlers, Limited or No handler support to coach for
                      matches. No match suggestions. You only get requests from
                      paying users if they come across your profile in their
                      pool and want to meet you. Beef up your dating game today,
                      become a premium subscriber
                    </p>
                    <button
                      onClick={() => {
                        handleNextStep();
                      }}
                      className="w-full sm:w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
                    >
                      Buy Subscription
                    </button>
                  </div>
                </div>
              ) : (
                <div className=" bg-white border-2 rounded-lg divide-y divide-gray-300">
                  <div className="flex w-full flex-col gap-3 px-4 sm:flex-row justify-between ">
                    <p className="text-[#081A51] p-4 w-max border-b-4 border-[#081A51] text-xs sm:text-sm font-semibold">
                      Purchase Subscription plan
                    </p>
                  </div>
                  <div className="p-4 px-4 sm:px-6 space-y-6">
                    <p className="text-[#223333] text-left text-sm sm:text-lg font-semibold">
                      Unlock Discover, and forge connections with other Nirvanae
                      hubbers{" "}
                    </p>
                    {singlePlan === "annually" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className=" text-sm max-md:text-[11px]">
                            View profiles in our private matchmaking dating
                            pool, get new suggestions in Discover daily.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Receive the Matchmaker’s Monthly Digest, a niche
                            publication sent to your mail.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Upon your request, get at least one(1) personalized
                            match from your handler, monthly.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Daily, receive more precise suggestions in Handler’s
                            Suggestions, 50 percent of which are on premium
                            annually.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Receive other suggestions from members on other
                            membership levels to boost your search.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Full handler support.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Access to system scheduled Blind Dates.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Get after match support.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Upon your request, get set up on personalized Blind
                            dates by Handler.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Upon your request, schedule one(1) therapy session
                            with a professional clinical psychologist.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Receive a full report that informs you about you,
                            and the kind of partners you should be looking out
                            for within our private matchmaking community.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className="text-sm max-md:text-[11px]">
                            Receive a full report on any match you want to make,
                            including match history, strike history, blacklist
                            report- and any other thing you’ll like to know
                            about a potential match, within our knowledge.
                          </p>
                        </div>
                      </div>
                    )}

                    {singlePlan === "bi-annually" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className=" text-sm max-md:text-[11px]">
                            View profiles in our private matchmaking dating
                            pool, get new random suggestions in Discover daily.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Receive the Matchmaker’s Monthly Digest, a niche
                            publication sent to your mail.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Upon your request, get at least one(1) personalized
                            match from your handler, monthly.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Daily, receive more precise suggestions in Handler’s
                            Suggestions, 50 percent of which are on premium
                            annually.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Receive other suggestions from members on other
                            membership levels to boost your search.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Full handler support.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Access to system scheduled Blind Dates.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Get after match support.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Upon your request, get set up on personalized Blind
                            dates by Handler.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No feedback on potential matches.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No scheduled therapy sessions with psychologist.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No access to therapy reports.
                          </p>
                        </div>
                      </div>
                    )}

                    {singlePlan === "quarterly" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className=" text-sm max-md:text-[11px]">
                            View profiles in our private matchmaking dating
                            pool, get new random suggestions in Discover daily.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Receive the Matchmaker’s Monthly Digest, a niche
                            publication sent to your mail.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Upon your request, get one(1) personalized match
                            from your handler, monthly.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Daily, receive more precise suggestions in Handler’s
                            Suggestions, 75 percent of which are other Premium
                            Quarterly subscribers.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Full handler support.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Access to system scheduled Blind Dates.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Get after match support.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No access to personalized Blind dates by Handler.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No scheduled therapy sessions with psychologist.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No access to therapy reports.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No feedback on potential matches.
                          </p>
                        </div>
                      </div>
                    )}
                    {singlePlan === "monthly" && (
                      <div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="mark" />
                          <p className=" text-sm max-md:text-[11px]">
                            View profiles in our private matchmaking dating
                            pool, get new random suggestions in Discover daily.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Receive the Matchmaker’s Monthly Digest, a niche
                            publication sent to your mail.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Daily, receive more precise suggestions in Handler’s
                            Suggestions, 90 percent of which are other monthly
                            subscribers.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Full handler support.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Access to system scheduled Blind Dates.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Mark} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            Get after match support.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No access to Blind dates by Handler.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No scheduled therapy sessions with psychologist.
                          </p>
                        </div>

                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No access to therapy reports.
                          </p>
                        </div>
                        <div className="flex mt-10">
                          <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                          <p className=" text-sm max-md:text-[11px]">
                            No feedback on potential matches.
                          </p>
                        </div>
                      </div>
                    )}

                    <p className="text-[#081A51] font-semibold text-sm">
                      Select Plan duration
                    </p>

                    <div className=" space-y-7 w-full">
                      <div className="w-full p-2 rounded-lg flex flex-wrap items-center gap-3 md:gap-4 xl:gap-5">
                        {majorPlans.map((plan) => (
                          <div
                            onClick={() => handleChoosePlan(plan)}
                            className={`${
                              singlePlan === plan?.plan
                                ? "bg-primary-bg px-4 py-2  rounded-md text-white text-center cursor-pointer lg:px-6"
                                : "text-primary-bg h-full rounded-md  text-center bg-[#E8EDFB] px-4 py-2 cursor-pointer lg:px-6"
                            }  `}
                          >
                            <p className="text-xs capitalize lg:text-sm xl:text-base">
                              {plan?.plan}
                            </p>
                            <p className="font-bold text-sm lg:text-base xl:text-xl">
                              {currency}{" "}
                              {Number(plan?.amount)?.toLocaleString?.()}
                            </p>
                          </div>
                        ))}
                      </div>
                      <div className="">
                        <PaystackPortal
                          currency={"NGN"}
                          plan_cost={amount}
                          amount={amount}
                          reference={user_reference}
                          email={user?.email}
                          plan={singlePlan}
                          onClose={() => {}}
                          mainCurrency={
                            country?.trim?.() === "nigeria" ? "NGN" : "USD"
                          }
                          onSuccess={() => {
                            paymentSuccessHandler({
                              plan: singlePlan,
                              amount,
                              email: user?.email,
                              tx_ref: user_reference,
                              transaction_id: user_reference,
                            });
                          }} //{paidSubmit}
                          title={`Pay ${
                            country?.trim?.() === "nigeria" ? "NGN" : "USD"
                          } ${Number(amount)?.toLocaleString?.()}`}
                          last_step_complete={true}
                          currency_code={"NGN"}
                          phone_number={undefined}
                          name={undefined}
                          plan_duration={undefined}
                          className="w-full sm:w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )
            ) : isViewDetails ? (
              <div className=" bg-white border-2 rounded-lg divide-y divide-gray-300">
                <div className="w-full p-3 sm:p-5 relative">
                  <span
                    onClick={() => setIsViewDetails(false)}
                    // style={{ transform: "translateY(-50%)" }}
                    className=" flex pb-2 w-max items-center hover:text-blue-600 cursor-pointer "
                  >
                    <KeyboardArrowLeft />
                    Go Back
                  </span>
                  <p className="text-[#081A51] capitalize text-sm sm:text-lg text-center font-semibold">
                    Premium plan benefits - (
                    {mainPlan?.toLowerCase?.()?.includes?.("usd")
                      ? `${mainPlan
                          ?.toLowerCase?.()
                          ?.replace?.(/-usd$/, "")} ${`(USD)`}`
                      : mainPlan}
                    )
                  </p>
                </div>

                {(mainPlan?.toLowerCase() === "annually" ||
                  mainPlan?.toLowerCase() === "annually-usd" ||
                  mainPlan?.toLowerCase() === "yearly") && (
                  <div className="p-4">
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="mark" />
                      <p className=" text-sm max-md:text-[11px]">
                        View profiles in our private matchmaking dating pool,
                        get new suggestions in Discover daily.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Receive the Matchmaker’s Monthly Digest, a niche
                        publication sent to your mail.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Upon your request, get at least one(1) personalized
                        match from your handler, monthly.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Daily, receive more precise suggestions in Handler’s
                        Suggestions, 50 percent of which are on premium
                        annually.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Receive other suggestions from members on other
                        membership levels to boost your search.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Full handler support.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Access to system scheduled Blind Dates.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Get after match support.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Upon your request, get set up on personalized Blind
                        dates by Handler.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Upon your request, schedule one(1) therapy session with
                        a professional clinical psychologist.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Receive a full report that informs you about you, and
                        the kind of partners you should be looking out for
                        within our private matchmaking community.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className="text-sm max-md:text-[11px]">
                        Receive a full report on any match you want to make,
                        including match history, strike history, blacklist
                        report- and any other thing you’ll like to know about a
                        potential match, within our knowledge.
                      </p>
                    </div>
                  </div>
                )}
                {(mainPlan?.toLowerCase() === "bi-annually" ||
                  mainPlan?.toLowerCase() === "bi-annually-usd" ||
                  mainPlan?.toLowerCase() === "bi-yearly") && (
                  <div className="p-4">
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="mark" />
                      <p className=" text-sm max-md:text-[11px]">
                        View profiles in our private matchmaking dating pool,
                        get new random suggestions in Discover daily.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Receive the Matchmaker’s Monthly Digest, a niche
                        publication sent to your mail.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Upon your request, get at least one(1) personalized
                        match from your handler, monthly.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Daily, receive more precise suggestions in Handler’s
                        Suggestions, 50 percent of which are on premium
                        annually.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Receive other suggestions from members on other
                        membership levels to boost your search.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Full handler support.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Access to system scheduled Blind Dates.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Get after match support.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Mark} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        Upon your request, get set up on personalized Blind
                        dates by Handler.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        No feedback on potential matches.
                      </p>
                    </div>
                    <div className="flex mt-10">
                      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        No scheduled therapy sessions with psychologist.
                      </p>
                    </div>

                    <div className="flex mt-10">
                      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                      <p className=" text-sm max-md:text-[11px]">
                        No access to therapy reports.
                      </p>
                    </div>
                  </div>
                )}
                {(mainPlan?.toLowerCase() === "quarterly" || mainPlan?.toLowerCase() === "quarterly-usd" )
                && 
                    <div className="p-4">
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="mark" />
                        <p className=" text-sm max-md:text-[11px]">
                          View profiles in our private matchmaking dating pool,
                          get new random suggestions in Discover daily.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          Receive the Matchmaker’s Monthly Digest, a niche
                          publication sent to your mail.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          Upon your request, get one(1) personalized match from
                          your handler, monthly.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          Daily, receive more precise suggestions in Handler’s
                          Suggestions, 75 percent of which are other Premium
                          Quarterly subscribers.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          Full handler support.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          Access to system scheduled Blind Dates.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Mark} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          Get after match support.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          No access to personalized Blind dates by Handler.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          No scheduled therapy sessions with psychologist.
                        </p>
                      </div>

                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          No access to therapy reports.
                        </p>
                      </div>
                      <div className="flex mt-10">
                        <img src={Cancel} className="h-6 mr-4" alt="cancel" />
                        <p className=" text-sm max-md:text-[11px]">
                          No feedback on potential matches.
                        </p>
                      </div>
                    </div>
                  }
              

                {(mainPlan?.toLowerCase() === "monthly" || mainPlan?.toLowerCase() === "monthly-usd") && (
  <div className="p-4">
    <div className="flex mt-10">
      <img src={Mark} className="h-6 mr-4" alt="mark" />
      <p className="text-sm max-md:text-[11px]">
        View profiles in our private matchmaking dating pool, get new random suggestions in Discover daily.
      </p>
    </div>

    <div className="flex mt-10">
      <img src={Mark} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">
        Receive the Matchmaker’s Monthly Digest, a niche publication sent to your mail.
      </p>
    </div>

    <div className="flex mt-10">
      <img src={Mark} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">
        Daily, receive more precise suggestions in Handler’s Suggestions, 90 percent of which are other monthly subscribers.
      </p>
    </div>

    <div className="flex mt-10">
      <img src={Mark} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">Full handler support.</p>
    </div>

    <div className="flex mt-10">
      <img src={Mark} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">Access to system scheduled Blind Dates.</p>
    </div>

    <div className="flex mt-10">
      <img src={Mark} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">Get after match support.</p>
    </div>

    <div className="flex mt-10">
      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">No access to Blind dates by Handler.</p>
    </div>

    <div className="flex mt-10">
      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">No scheduled therapy sessions with psychologist.</p>
    </div>

    <div className="flex mt-10">
      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">No access to therapy reports.</p>
    </div>

    <div className="flex mt-10">
      <img src={Cancel} className="h-6 mr-4" alt="cancel" />
      <p className="text-sm max-md:text-[11px]">No feedback on potential matches.</p>
    </div>
  </div>
)}

              </div>
            ) : (
              <div className=" bg-white border-2 rounded-lg divide-y divide-gray-300">
                {user?.mode === "paystack" ? (
                  <>
                    <div className="flex w-full p-5 justify-between items-center">
                      <p className="text-[#081A51] font-semibold">
                        Membership Subscription
                      </p>
                    </div>
                    <div className="p-5 space-y-5">
                      <div className="rounded-lg  border-t-blue-400 divide-y divide-gray-300 border border-t-4">
                        <div className="p-4">
                          <h2 className="text-lg font-semibold">
                            Premium plan
                          </h2>
                          <p className="text-xs">
                            Enjoy access to handlers, personal
                            therapist/handler, suggestions and more.
                          </p>
                        </div>
                        <div className="p-1">
                          <div
                            onClick={() => setIsViewDetails(true)}
                            className="flex p-3 cursor-pointer hover:bg-gray-100 justify-between items-center"
                          >
                            <h2 className="text-sm">View more details</h2>
                            <KeyboardArrowRight />
                          </div>
                        </div>
                      </div>
                      <p className="text-[#081A51] font-semibold">
                        Payment Info
                      </p>
                      <div className="rounded-lg  border-t-blue-400 divide-y divide-gray-300 border border-t-4">
                        <div className="p-4">
                          <h2 className="text-xs">Payment Plan</h2>
                          <p className="text-base capitalize font-semibold">
                            {mainPlan?.toLowerCase?.()?.includes?.("usd")
                              ? `${mainPlan
                                  ?.toLowerCase?.()
                                  ?.replace?.(/-usd$/, "")} ${`(USD)`}`
                              : mainPlan}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Expires In</h2>
                          <p className="text-base font-semibold">
                            {calculateExpirationDate(
                              user?.Invoice?.[0]?.created_at,
                              mainPlan
                            ) !== "expired"
                              ? `${calculateDaysLeft(
                                  calculateExpirationDate(
                                    user?.Invoice?.[0]?.created_at,
                                    mainPlan
                                  )
                                )} days left`
                              : "expired"}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Payment Method</h2>
                          <p className="text-base items-start text-blue-400 flex gap-2 sm:items-center font-semibold">
                            <CreditCard color="inherit" />
                            <div className="flex gap-4 sm:gap-10 items-start flex-col sm:flex-row sm:items-center">
                              Paystack
                              {user?.paystackSubscription?.[0]?.last4 && (
                                <div className="px-4 py-2.5 text-black rounded-lg border border-gray-400">
                                  <div className="flex justify-between gap-10 items-center">
                                    <div>
                                      <h2 className="text-sm font-semibold">
                                        **** **** ****{" "}
                                        {mainPaystack?.last4 ??
                                          user?.paystackSubscription?.[0]
                                            ?.last4}
                                      </h2>
                                      <p className="text-xs">
                                        {mainPaystack?.card_type ??
                                          user?.paystackSubscription?.[0]
                                            ?.card_type}
                                      </p>
                                    </div>
                                    <p className="text-xs">
                                      {mainPaystack?.exp_month ??
                                        user?.paystackSubscription?.[0]
                                          ?.exp_month}{" "}
                                      /{" "}
                                      {mainPaystack?.exp_year?.slice(2) ??
                                        user?.paystackSubscription?.[0]?.exp_year?.slice(
                                          2
                                        )}{" "}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs pb-1">
                            Recurring Subscription
                          </h2>
                          {mainPaystack?.active ? (
                            <p className="text-xs px-4 w-max py-1 bg-blue-100 rounded-lg border-2 border-blue-600 text-blue-600 flex gap-2 items-center font-semibold">
                              Enabled
                            </p>
                          ) : (
                            <p className="text-xs px-4 w-max py-1 bg-gray-100 rounded-lg border-2 border-gray-600 text-gray-600 flex gap-2 items-center font-semibold">
                              Non-recurring (Disabled)
                            </p>
                          )}
                        </div>
                        {mainPaystack?.active && (
                          <div className="p-4">
                            <div className="flex justify-center items-center">
                              <button
                                onClick={() => {
                                  setOpenConfirm(true);
                                }}
                                className="w-full sm:w-max justify-center text-white text-xs font-medium sm:text-sm flex items-center bg-[#081A51] px-4 sm:px-6 py-2.5 rounded-lg"
                              >
                                Cancel Recurring Membership Subscription
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex w-full p-5 justify-between items-center">
                      <p className="text-[#081A51] font-semibold">
                        Membership Subscription
                      </p>
                    </div>
                    <div className="p-5 space-y-5">
                      <div className="rounded-lg  border-t-blue-400 divide-y divide-gray-300 border border-t-4">
                        <div className="p-4">
                          <h2 className="text-lg font-semibold">
                            Premium plan
                          </h2>
                          <p className="text-xs">
                            Enjoy access to handlers, personal
                            therapist/handler, suggestions and more.
                          </p>
                        </div>
                        <div className="p-1">
                          <div
                            onClick={() => setIsViewDetails(true)}
                            className="flex p-3 cursor-pointer hover:bg-gray-100 justify-between items-center"
                          >
                            <h2 className="text-sm">View more details</h2>
                            <KeyboardArrowRight />
                          </div>
                        </div>
                      </div>
                      <p className="text-[#081A51] font-semibold">
                        Payment Info
                      </p>
                      <div className="rounded-lg  border-t-blue-400 divide-y divide-gray-300 border border-t-4">
                        <div className="p-4">
                          <h2 className="text-xs">Payment Plan</h2>
                          <p className="text-base capitalize font-semibold">
                            {user?.plan}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Last Payment</h2>
                          <p className="text-base font-semibold">
                            {moment(user?.paymentDate ?? new Date())?.format?.(
                              "Do MMMM YYYY"
                            )}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Valid until</h2>
                          <p className="text-base font-semibold">
                            {calculateExpirationDate(
                              user?.paymentDate,
                              user?.plan?.toLowerCase?.()
                            ) === "expired"
                              ? "Expired"
                              : moment(
                                  calculateExpirationDate(
                                    user?.paymentDate,
                                    user?.plan?.toLowerCase?.()
                                  ) ?? new Date()
                                )?.format?.("Do MMMM YYYY")}
                          </p>
                        </div>
                        <div className="p-4">
                          <h2 className="text-xs">Payment Method</h2>
                          <p className="text-base text-blue-400 flex gap-2 items-center font-semibold">
                            <AssignmentInd color="inherit" />
                            Payment through handler
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default RevisedAuthPlan;
